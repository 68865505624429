<script setup>
import { ref, nextTick, computed } from "vue";
import { useActiveItemStore } from "@/stores/activeItemStore";
import { useNavStore } from "@/stores/navStore";
import VideoPlayer from "./VideoPlayer.vue";
import keyboardArrowLeft from "./svg/keyboard_arrow_left-24px.svg";
import keyboardArrowRight from "./svg/keyboard_arrow_right-24px.svg";
import close from "./svg/close-24px.svg";

const props = defineProps({
  attrs: Array,
  project: Object,
});

// RECEIVED PROPS
// destructure the prop
const { project } = props;

// REACTIVE PROPS FROM STORE/S
const activeItemStore = useActiveItemStore();
const navStore = useNavStore();

// REACTIVE/MUTATABLE VARIABLES
const currentSlide = ref(0);
const projectHovered = ref(false);

// GENERAL VARIABLES
const newActiveItemId = props.project.id;

// COMPUTED

let isVideo = computed(() => {
  if (props.project.slides?.[currentSlide.value]?.mediaType == "video") {
    return true;
  }
  return false;
});

let isImgLink = computed(() => {
  if (props.project.slides?.[currentSlide.value]?.mediaType == "imgLink") {
    return true;
  }
  return false;
});

let nextSlideIndex = computed(() => {
  if (currentSlide.value < props.project.slides.length - 1) {
    return currentSlide.value + 1;
  }
  if (currentSlide.value == props.project.slides.length - 1) {
    return 0;
  }
});

let prevSlideIndex = computed(() => {
  if (currentSlide.value - 1 < 0) {
    return props.project.slides.length - 1;
  }
  if (currentSlide.value - 1 >= 0) {
    return currentSlide.value - 1;
  }
});

let videoPath = computed(() => {
  let vidSrc;
  if (window.innerWidth <= 854) {
    vidSrc = `video/small/${
      props.project.slides[currentSlide.value].videoName
    }.${props.project.slides[currentSlide.value].videoType}`;
  } else {
    vidSrc = `video/large/${
      props.project.slides[currentSlide.value].videoName
    }.${props.project.slides[currentSlide.value].videoType}`;
  }
  return vidSrc;
});

// METHODS

const projectSetHover = () => {
  return (projectHovered.value = true);
};

const projectUnsetHover = () => {
  return (projectHovered.value = false);
};

const activation = async () => {
  activeItemStore.setActivatedItemIds(newActiveItemId);
  scrollToExpanded();
  navStore.setScrollTimeout();
  preload();
};

const recursiveDivCheckerExpandedItem = () => {
  var itemRenderScroll = document.getElementById(props.project.id + "-full");
  if (itemRenderScroll) {
    itemRenderScroll.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  } else {
    setTimeout(recursiveDivCheckerExpandedItem, 100);
  }
};

const recursiveDivCheckerCollapsedItem = () => {
  var itemRenderScroll = document.getElementById(
    activeItemStore.prevActiveItemId
  );
  if (itemRenderScroll) {
    itemRenderScroll.scrollIntoView({
      block: "start",
      behavior: "auto",
    });
  } else {
    setTimeout(recursiveDivCheckerCollapsedItem, 100);
  }
};

const scrollToExpanded = () => {
  var scrolltag = props.project.id;
  var parent = document.getElementById(scrolltag);
  if (parent) {
    recursiveDivCheckerExpandedItem();
  } else {
  }
};

const next = async () => {
  document
    .querySelector(".slide-content")
    .classList.add("slide-transition-next");
  setTimeout(removeSlideTransitionClasses, 500);

  // reset the current slide index to the correct value
  if (currentSlide.value + 1 < props.project.slides.length) {
    // increment the value of the current slide
    currentSlide.value++;
  } else {
    currentSlide.value = 0;
  }
  // preload the "new" slide's next and prev images if they are not a video slide
  preload();
};

const preload = () => {
  const imageArray = props.project.slides;

  if (props.project.slides?.[nextSlideIndex.value]?.mediaType != "video") {
    let nextImg = new Image();
    nextImg.src =
      "images/fullscreen/" + imageArray[nextSlideIndex.value].slideImg;
  }

  if (props.project.slides?.[prevSlideIndex.value]?.mediaType != "video") {
    let prevImg = new Image();
    prevImg.src =
      "images/fullscreen/" + imageArray[prevSlideIndex.value].slideImg;
  }
};

const removeSlideTransitionClasses = () => {
  document
    .querySelector(".slide-content")
    .classList.remove("slide-transition-next", "slide-transition-prev");
};

const prev = async () => {
  if (currentSlide.value !== 0) {
    currentSlide.value--;
  } else {
    currentSlide.value = props.project.slides.length - 1;
  }
  document
    .querySelector(".slide-content")
    .classList.add("slide-transition-prev");
  setTimeout(removeSlideTransitionClasses, 500);
};

const closeBtn = () => {
  closeAll();
};

const closeAll = async () => {
  currentSlide.value = 0;
  activeItemStore.deactivateAll();
  await nextTick();
  scrollToJustCollapsed();
};

const scrollToJustCollapsed = () => {
  var scrolltag = activeItemStore.prevActiveItemId;
  var parent = document.getElementById(scrolltag);
  if (parent) {
    recursiveDivCheckerCollapsedItem();
  } else {
  }
};

const replaceToString = (camelString) => {
  var newString = camelString.replace(/^[a-z]|[A-Z]/g, function (v, i) {
    return i === 0 ? v.toUpperCase() : " " + v;
  });
  return newString;
};
</script>

<template>
  <div
    :id="project.id"
    class="unit"
    :class="{ active: newActiveItemId == activeItemStore.activeItemId }"
    :data-group="project.dataGroup"
  >
    <!-- preview   -->

    <button
      class="image-square"
      :class="{
        hidden: newActiveItemId == activeItemStore.activeItemId,
        hover2In: projectHovered,
        hover2Out: !projectHovered,
      }"
      @click="activation()"
      @mouseover="projectSetHover()"
      @mouseleave="projectUnsetHover()"
    >
      <img
        class=""
        :src="'images/' + project.displayImg"
        :alt="replaceToString(project.id) + ' Project'"
      />
      <transition name="fade">
        <div v-if="projectHovered" class="project-hover">
          <div class="project-title">
            <h2>{{ project.title }}</h2>
          </div>
        </div>
      </transition>
    </button>

    <!-- full -->
    <div
      v-if="newActiveItemId == activeItemStore.activeItemId"
      :id="project.id + '-full'"
      class="full-item"
    >
      <button class="circle-btn close-btn" alt="close" @click="closeBtn()">
        <close />
      </button>

      <div class="top-text-blocks full-paddings med-width text-center">
        <div class="details-heading">
          <h2 v-if="project.richTitle" v-html="project.richTitle" />
          <h2 v-else>{{ project.title }}</h2>
        </div>
        <div class="main-caption">
          <p v-html="project.introCaption" />
        </div>
        <div
          v-if="project.slides[currentSlide].slideHeading"
          class="slide-heading"
        >
          <h3
            v-if="project.slides[currentSlide].richSlideHeading"
            v-html="project.slides[currentSlide].richSlideHeading"
          />
          <h3 v-else>{{ project.slides[currentSlide].slideHeading }}</h3>
        </div>
      </div>

      <div class="image-controller-container">
        <div class="image-fullscreen">
          <transition name="slide-fade">
            <div class="slide-content">
              <VideoPlayer
                v-if="isVideo"
                :src="videoPath"
                :type="`video/${project.slides[currentSlide].videoType}`"
              />
              <a
                v-if="isImgLink"
                class="full-img"
                :href="project.slides[currentSlide].slideLink"
                target="_blank"
                ><img
                  :id="newActiveItemId"
                  class="full-img-link"
                  :src="
                    'images/fullscreen/' + project.slides[currentSlide].slideImg
                  "
                  :style="{
                    maxWidth: project.slides[currentSlide].maxWidth,
                  }"
                  :alt="
                    project.title +
                    ' ' +
                    project.slides[currentSlide].slideHeading
                  "
                />
              </a>
              <img
                v-if="!isVideo && !isImgLink"
                :id="newActiveItemId"
                class="full-img"
                :src="
                  'images/fullscreen/' + project.slides[currentSlide].slideImg
                "
                :style="{
                  maxWidth: project.slides[currentSlide].maxWidth,
                }"
                :alt="
                  project.title +
                  ' ' +
                  project.slides[currentSlide].slideHeading
                "
              />
            </div>
          </transition>
        </div>
        <div class="image-fullscreen">
          <div v-if="project.slides.length > 1" class="slide-controller">
            <div class="slide-controls">
              <button class="button previous" @click="prev()">
                <div class="arrows">
                  <keyboardArrowLeft />
                  <keyboardArrowLeft />
                </div>
                <span>prev</span>
              </button>
              <button :project="project" class="button next" @click="next()">
                <span>next</span>
                <div class="arrows">
                  <keyboardArrowRight />
                  <keyboardArrowRight />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="full-paddings med-width text-center">
        <div v-if="project.repeatedHeading" class="repeated-heading">
          <h3 v-html="project.repeatedHeading" />
        </div>
        <div class="slide-caption">
          <div v-html="project.slides[currentSlide].slideCaption" />
        </div>
        <div v-if="props.project.repeatedCaption" class="repeated-caption">
          <p v-html="props.project.repeatedCaption" />
        </div>
      </div>
    </div>
  </div>
</template>
