<template>
  <section id="navigation">
    <div class="nav-top">
      <div class="wave"></div>
    </div>

    <div class="navigation">
      <nav class="menu filter wrap-width">
        <ul>
          <li>
            <a
              href="#"
              data-group="all"
              class="sort-active"
              @click="filter($event)"
              >See All</a
            >
          </li>
          <li><a href="#" data-group="web" @click="filter($event)">Web</a></li>
          <li><a href="#" data-group="app" @click="filter($event)">App</a></li>
          <li>
            <a href="#" data-group="print" @click="filter($event)">Print</a>
          </li>
          <li>
            <a href="#" data-group="illustration" @click="filter($event)"
              >Illustration</a
            >
          </li>
          <li>
            <a href="#" data-group="motion" @click="filter($event)">Motion</a>
          </li>
          <li>
            <a href="#" data-group="code" @click="filter($event)">Code</a>
          </li>
        </ul>
      </nav>
    </div>

    <div id="portfolio" class="text wrap-width">
      <SingleItem
        is="SingleItem"
        v-for="item of projectsList(projects, currentFilter)"
        :key="item.id"
        :index="index"
        :project="item"
        :name="item.id"
      />
    </div>
  </section>
</template>
<script setup>
import SingleItem from "./SingleItem.vue";
import { ref } from "vue";
import projects from "../data/data.json";

const emit = defineEmits(["execute-filter"]);

// REACTIVE/MUTATABLE VARIABLES
let currentFilter = ref("all");

// METHODS

const filter = (event) => {
  event.preventDefault();
  // remove current filter
  document
    .querySelector(".menu li a.sort-active")
    .classList.remove("sort-active");
  // set new active filter
  event.target.classList.add("sort-active");
  currentFilter.value = event.target.getAttribute("data-group");
  emit("execute-filter", currentFilter.value);
  scrollToFilters();
};

const projectsList = (projects, currentFilter) => {
  return projects.filter(function (project) {
    return project.dataGroup.includes(currentFilter);
  });
};

const scrollToFilters = () => {
  document.querySelector(".navigation").scrollIntoView({
    block: "start",
    behavior: "smooth",
  });
};
</script>
